<template>
  <div>
    <MiniBanner />
    <div>
      <van-empty description="暂无会议回放" />
    </div>
  </div>
</template>

<script>
import MiniBanner from "@/components/miniBanner/index";
import { mapActions } from "vuex";

export default {
  name: "MiniPlayback",
  components: {
    MiniBanner,
  },
  methods: {
    ...mapActions({
      setTitle: "setTitleFunc",
    }),
  },
  created() {
    this.setTitle("会议回放");
  },
};
</script>

<style>
</style>